<template>
  <div>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol class="pills-wrapper">
                <b-nav pills>
                  <b-nav-item
                    @click="selectStatus('All')"
                    :active="active === 'All'"
                    >All</b-nav-item
                  >
                  <b-nav-item
                    @click="selectStatus('started')"
                    :active="active === 'started'"
                    >Started</b-nav-item
                  >
                  <b-nav-item
                    @click="selectStatus('processing')"
                    :active="active === 'processing'"
                    >Processing</b-nav-item
                  >
                  <b-nav-item
                    @click="selectStatus('completed')"
                    :active="active === 'completed'"
                    >Completed</b-nav-item
                  >
                  <b-nav-item
                    @click="selectStatus('failed')"
                    :active="active === 'failed'"
                    >Failed</b-nav-item
                  >
                </b-nav>
              </CCol>
              <CCol>
                <CInput
                  :placeholder="$t('PAYMENTS.SEARCH')"
                  v-model="paymentFilters.search"
                  @input="getPaymentDetails"
                  class="px-2 float-right col-sm-6"
                />

                <CButton
                  @click="filterModal = true"
                  size="sm"
                  color="lightborder"
                  class="px-2 float-right"
                >
                  <CIcon name="cil-filter" />&nbsp;{{ $t("COMMON.FILTERS") }}
                </CButton>

                <vue-json-to-csv
                  v-if="payments !== null"
                  :json-data="payments_export"
                  :labels="getCSVLables()"
                  :csv-title="getCSVName('payments')"
                  class="px-2 float-right"
                >
                  <CButton
                    @click="getPaymentDetailsForExport"
                    size="sm"
                    color="lightborder"
                  >
                    <CIcon name="cil-cloud-download" />&nbsp;{{
                      $t("COMMON.EXPORT")
                    }}
                  </CButton>
                </vue-json-to-csv>
              </CCol>
            </CRow>
            <CDataTable
              hover
              :items="payments"
              :fields="fields"
              @row-clicked="(item, index) => goTo('/payments/', item['id'])"
            >
              <template #status="data">
                <td>
                  <CBadge :color="getPaymentBadge(data.item.status)">{{
                    getCapitalized(data.item.status)
                  }}</CBadge>
                </td>
              </template>
              <template #is_live="data">
                <td>
                  <div>{{ getType(data.item.is_live) }}</div>
                </td>
              </template>
              <template #payment_details_type="data">
                <td>
                  <div>{{ getPayType(data.item.payment_details_type) }}</div>
                </td>
              </template>
              <template #created_at="data">
                <td>
                  <div>
                    {{ formatDate(data.item.created_at) }}
                  </div>
                </td>
              </template>
            </CDataTable>
            <div class="col-md-11">
              <b-pagination
                size="md"
                align="center"
                :total-rows="totalRows"
                @change="pageChange"
                v-model="pageNumber"
                :per-page="paymentFilters.limit"
              ></b-pagination>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :title="$t('COMMON.FILTERS_HEADER')"
      color="light"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="filterModal"
    >
      <CContainer>
        <label class="row col-12 col-xl-12">{{
          $t("ORDERS.FILTER_BY.DATE")
        }}</label>
        <CRow class="mb-3">
          <date-picker
            class="primary col-12 col-xl-12"
            v-model="dates"
            type="date"
            range
            placeholder="Select date range"
            @change="selectRange"
          ></date-picker>
        </CRow>
        <CRow class="mb-3">
          <label class="col-12 col-xl-12">{{
            $t("PAYMENTS.FILTERS.PAYMENT_TYPE")
          }}</label>
          <multiselect
            v-model="value2"
            class="mx-3"
            :options="options2"
            :multiple="false"
            :placeholder="$t('PAYMENTS.FILTERS.PAYMENT_TYPE')"
            track-by="name"
            label="name"
            @select="selectFilter2"
            @remove="clearFilter2"
          ></multiselect>
        </CRow>
      </CContainer>
      <template #footer>
        <CButton @click="resetFilter">{{
          $t("COMMON.RESET")
        }}</CButton>
        <CButton @click="filterModal = false" color="durianprimary">{{
          $t("COMMON.DONE")
        }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { listPayment } from "@/api/payment.api.js";
import VueJsonToCsv from "vue-json-to-csv";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { sandboxEventEmitter } from "@/../config.js";

const COMPONENT_NAME = "Payments";
export default {
  components: {
    Multiselect,
    VueJsonToCsv,
    DatePicker,
  },
  name: COMPONENT_NAME,
  data() {
    return {
      active: "All",
      filterModal: false,
      visible: false,
      totalRows: 0,
      fields: [
        {
          key: "id",
          label: this.$t("PAYMENTS.TABLE.3"),
          _classes: "font-weight-bold",
        },
        { key: "amount", label: this.$t("PAYMENTS.TABLE.2") },
        { key: "currency", label: "Currency", _classes: "font-family-aperchu" },
        { key: "payment_details_type", label: this.$t("PAYMENTS.TABLE.5") },
        { key: "customer_name", label: this.$t("ORDERS.CSV.NAME") },
        { key: "created_at", label: this.$t("PAYMENTS.TABLE.6") },
        { key: "status", label: this.$t("PAYMENTS.TABLE.1") },
      ],
      paymentFilters: {
        skip: 0,
        limit: 50,
        filters: [],
        from: "",
        to: "",
        search: "",
      },
      options1: [
        { name: "paid", column: "status" },
        { name: "started", column: "status" },
        { name: "partial", column: "status" },
        { name: "processed", column: "status" },
        { name: "completed", column: "status" },
      ],
      options2: [
        { name: "Credit/Debit Cards", column: "payment_details_type" },
        { name: "E-Wallets", column: "payment_details_type" },
        { name: "Virtual Account", column: "payment_details_type" },
      ],
      options3: [
        { name: "Live", column: "is_live" },
        { name: "Sandbox", column: "is_live" },
      ],
      value1: null,
      value2: null,
      value3: null,
      payments: [],
      selected: null,
      pageNumber: 1,
      value: "",
      valueto: "",
      today: "",
      before: "",
      CSVLables: {
        id: {},
        created_at: {},
        order_id: {},
        order_ref_id: {},
        currency: {},
        amount: {},
        status: {},
        payment_details_type: {},
        updated_at: {},
      },
      payments_export: [],
      range: {
        start: new Date(2020, 0, 1),
        end: new Date(),
      },
      dates: [],
    };
  },
  methods: {
    selectRange() {
      if (this.ifValid(this.dates[0]) && this.ifValid(this.dates[1])) {
        this.paymentFilters.from = this.moment(this.dates[0])
          .format("YYYY-MM-DD")
          .toString();
        this.paymentFilters.to = this.moment(this.dates[1])
          .format("YYYY-MM-DD")
          .toString();
      } else {
        this.paymentFilters.from = this.before;
        this.paymentFilters.to = this.today;
      }
      this.getPaymentsByDate();
    },
    resetFilter() {
      this.dates = [];
      this.value2 = null;
      this.removeFromPaymentFilter("payment_details_type");
      this.initializeData();
    },
    onContext(ctx) {
      if (ctx.selectedDate != null) {
        let date = this.moment(ctx.selectedDate).format("MM-DD-YYYY");
        this.paymentFilters.from = date.toString();
      } else {
        this.paymentFilters.from = "";
      }
      this.getPaymentsByDate();
    },
    onContextTo(ctx) {
      if (ctx.selectedDate != null) {
        let date = this.moment(ctx.selectedDate).format("MM-DD-YYYY");
        this.paymentFilters.to = date.toString();
      } else {
        this.paymentFilters.to = "";
      }
      this.getPaymentsByDate();
    },
    getPaymentsByDate() {
      if (this.paymentFilters.from && this.paymentFilters.to) {
        this.getPaymentDetails();
      } else if (
        this.paymentFilters.from == "" &&
        this.paymentFilters.to == ""
      ) {
        this.getPaymentDetails();
      }
    },
    pageChange(val) {
      this.paymentFilters.skip = (val - 1) * this.paymentFilters.limit;
      this.getPaymentDetails();
    },
    removeFromPaymentFilter(val) {
      let removeIndex = -1;
      this.paymentFilters.filters.find((v, i) => {
        if (v.key === val) {
          removeIndex = i;
        }
      });
      if (removeIndex != -1) {
        this.paymentFilters.filters.splice(removeIndex, 1);
      }
    },
    selectFilter1(val) {
      this.removeFromPaymentFilter(val.column);
      let f = {};
      f.value = val.name;
      f.key = val.column;
      this.paymentFilters.filters.push(f);
      this.paymentFilters.skip = 0;
      this.getPaymentDetails();
    },
    clearFilter1(val) {
      this.value1 = null;
      let removeElementAtIndex = -1;
      this.paymentFilters.filters.forEach(function (v, index) {
        if (v.key === val.column) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.paymentFilters.filters.splice(removeElementAtIndex, 1);
      }
      this.getPaymentDetails();
    },
    selectFilter2(val) {
      this.removeFromPaymentFilter(val.column);
      let f = {};
      if (val.name == "Credit/Debit Cards") {
        f.value = "card_details";
      } else if (val.name == "E-Wallets") {
        f.value = "ewallet_details";
      } else {
        f.value = "va_details";
      }
      f.key = val.column;
      this.paymentFilters.filters.push(f);
      this.paymentFilters.skip = 0;
      this.getPaymentDetails();
    },
    clearFilter2(val) {
      this.value2 = null;
      let removeElementAtIndex = -1;
      this.paymentFilters.filters.forEach(function (v, index) {
        if (v.key === val.column) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.paymentFilters.filters.splice(removeElementAtIndex, 1);
      }
      this.getPaymentDetails();
    },
    selectFilter3(val) {
      this.removeFromPaymentFilter(val.column);
      let f = {};
      if (val.name == "Live") {
        f.value = "true";
      } else {
        f.value = "false";
      }
      f.key = val.column;
      this.paymentFilters.filters.push(f);
      this.paymentFilters.skip = 0;
      this.getPaymentDetails();
    },
    clearFilter3(val) {
      this.value3 = null;
      let removeElementAtIndex = -1;
      this.paymentFilters.filters.forEach(function (v, index) {
        if (v.key === val.column) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.paymentFilters.filters.splice(removeElementAtIndex, 1);
      }
      this.getPaymentDetails();
    },
    getPaymentDetails() {
      const {
        skip,
        limit,
        from,
        to,
        search,
        filters,
      } = this.paymentFilters;
      let paramsMap = new Map();
      paramsMap["skip"] = skip;
      paramsMap["limit"] = limit;
      paramsMap["from"] = from || this.before;
      paramsMap["to"] = to || this.today;
      paramsMap["search"] = search;
      paramsMap["filters"] = JSON.stringify(filters);

      listPayment(this.generatePostParams(paramsMap))
        .then((response) => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.payments = response.data.data.payments;
            this.totalRows = response.data.data.count;
            this.initialiseExportData();
            if (this.payments.length > 0) {
              for (let i = 0; i < this.payments.length; i++) {
                this.payments[i].amount = this.formatAmount(
                  this.payments[i].amount
                );
              }
            }
          }
          })
          .catch(this.handleAPIError);
    },
    initialiseExportData() {
      const {
        from,
        to,
        search,
        filters,
      } = this.paymentFilters;
      let paramsMap = new Map();
      paramsMap["skip"] = 0;
      paramsMap["limit"] = this.totalRows;
      paramsMap["from"] = from
      paramsMap["to"] = to
      paramsMap["search"] = search;
      paramsMap["filters"] = JSON.stringify(filters);
      listPayment(this.generatePostParams(paramsMap))
        .then((response) => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.payments_export = response.data.data.payments;
          }
        })
        .catch(this.handleAPIError);
    },
    getPaymentDetailsForExport() {
      this.cleanCSVData();
    },
    getCSVLables() {
      this.CSVLables.created_at.title = this.$t("ORDERS.CSV.DATE");
      this.CSVLables.id.title =
        this.$t("COMMON.APPNAME") + this.$t("PAYMENTS.TABLE.3");
      this.CSVLables.order_id.title = this.$t("PAYMENTS.TABLE.4");
      this.CSVLables.order_ref_id.title = this.$t("ORDERS.TABLE.6");
      this.CSVLables.currency.title = this.$t("ORDERS.TABLE.7");
      this.CSVLables.amount.title = this.$t("ORDERS.TABLE.2");
      this.CSVLables.status.title = this.$t("PAYMENTS.TABLE.1");
      this.CSVLables.payment_details_type.title = this.$t("ORDERS.CSV.PAYTYPE");
      this.CSVLables.updated_at.title = this.$t("ORDERS.CSV.UPDATEDDATE");
      return this.CSVLables;
    },
    cleanCSVData() {
      if (this.payments_export.length > 0) {
        for (let i = 0; i < this.payments_export.length; i++) {
          this.payments_export[i].created_at = this.formatDate(
            this.payments_export[i].created_at
          );
          this.payments_export[i].updated_at = this.formatDate(
            this.payments_export[i].updated_at
          );
          this.payments_export[i].payment_details_type = this.getPayType(
            this.payments_export[i].payment_details_type
          );
        }
      }
    },
    selectStatus(val) {
      this.active = val;
      let filter = {};
      filter.column = "status";
      filter.name = val;
      if (val != "All") {
        this.selectFilter1(filter);
      } else {
        this.clearFilter1(filter);
      }
    },
    getLabelKey(key) {
      switch (key) {
        case "id":
          return this.$t("PAYMENTS.TABLE.3");
        case "order_id":
          return this.$t("PAYMENTS.TABLE.4");
        case "payment_ref_id":
          return this.$t("PAYMENTS.TABLE.7");
        case "amount":
          return this.$t("PAYMENTS.TABLE.2");
        case "status":
          return this.$t("PAYMENTS.TABLE.1");
        case "is_live":
          return this.$t("PAYMENTS.TABLE.8");
        case "expiration_date":
          return this.$t("PAYMENTS.TABLE.9");
        case "payment_details_type":
          return this.$t("PAYMENTS.TABLE.5");
        case "created_at":
          return this.$t("PAYMENTS.TABLE.6");
        default:
          return key;
      }
    },
    async initializeData() {
      const dates = await this.initializeDates();
      this.today = dates.today;
      this.before = dates.before;
      this.paymentFilters.to = this.today;
      this.paymentFilters.from = this.before;
      this.getPaymentDetails();
    },
  },
  mounted() {
    this.initializeData();
  },
  created() {
    this.$eventHub.$on(sandboxEventEmitter.prefix + COMPONENT_NAME, this.initializeData);
  },
  beforeDestroy(){
    this.$eventHub.$off(sandboxEventEmitter.prefix + COMPONENT_NAME);
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
